<template>
    <ContainerWithMenu>
        <div class="page-content">
            <div class="row margin-bottom-20">
                <div class="col-xs-12 col-md-5">
                    <h1 class="h2 margin-0"><i class="las la-dna"></i> {{project_research.name}}</h1>
                </div>
                <div class="col-xs-12 col-md-7 text-right">
                    <button
                            class="btn btn-default btn-action-mobile btn-undo-mobile"
                            @click="$router.push({name:'home'})"
                    >
                        <i class="las la-undo"></i>
                        <span class="button-text">Torna alle procedure</span>
                    </button>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-xs-12 col-sm-4" v-show="project_research.has_medical_history">
                    <router-link to="">
                        <div class="panel panel-default">
                            <div class="panel-body" style="text-align: center">
                                <i class="las la-file-medical-alt margin-bottom-5" style="font-size:40px;"></i>
                                <h4>Medical History</h4>
                                <button class="btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12"
                                        @click.prevent.stop="$router.push({name:'entries',
                                         params: { research_project_id: $route.params.research_project_id,
                                          category_id: CategoryEntry.MEDICAL_HISTORY}})">
                                    <i class="las la-eye"></i>
                                    Mostra
                                </button>
                                <button @click.prevent.stop="downloadExcel(1)" class="btn btn-default margin-top-10 col-xs-12 col-md-12 col-lg-12">
                                    <i class="las la-file-download"></i>
                                    Esporta
                                </button>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xs-12 col-sm-4" v-show="project_research.has_ablation">
                    <router-link to="">
                        <div class="panel panel-default">
                            <div class="panel-body"  style="text-align: center">
                                <i class="las la-procedures margin-bottom-5" style="font-size:40px;"></i>
                                <h4>Ablation Procedure</h4>
                                <button class="btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12"
                                        @click.prevent.stop="$router.push({name:'entries',
                                         params: { research_project_id: $route.params.research_project_id,
                                          category_id: CategoryEntry.ABLATION_PROCEDURE}})">
                                    <i class="las la-eye"></i>
                                    Mostra
                                </button>
                                <button @click.prevent.stop="downloadExcel(2)" class="btn btn-default margin-top-10 col-xs-12 col-md-12 col-lg-12">
                                    <i class="las la-file-download"></i>
                                    Esporta
                                </button>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xs-12 col-sm-4" v-show="project_research.has_follow_up">
                    <router-link to="">
                        <div class="panel panel-default">
                            <div class="panel-body"  style="text-align: center">
                                <i class="las la-notes-medical margin-bottom-5" style="font-size:40px;"></i>
                                <h4>Follow-up</h4>
                                <button class="btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12"
                                        @click.prevent.stop="$router.push({name:'entries',
                                         params: { research_project_id: $route.params.research_project_id,
                                          category_id: CategoryEntry.FOLLOW_UP}})">
                                    <i class="las la-eye"></i>
                                    Mostra
                                </button>
                                <button @click.prevent.stop="downloadExcel(3)" class="btn btn-default margin-top-10 col-xs-12 col-md-12 col-lg-12">
                                    <i class="las la-file-download"></i>
                                    Esporta
                                </button>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xs-12 col-sm-4">
                    <router-link to="">
                        <div class="panel panel-default">
                            <div class="panel-body"  style="text-align: center">
                                <i class="las la-users margin-bottom-5" style="font-size:40px;"></i>
                                <h4>Pazienti</h4>
                                <button class="btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12"
                                        @click.prevent.stop="$router.push({name:'patients',
                                         params: { research_project_id: $route.params.research_project_id }})">
                                    <i class="las la-eye"></i>
                                    Mostra
                                </button>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-xs-12 col-sm-4">
                    <router-link to="">
                        <div class="panel panel-default">
                            <div class="panel-body"  style="text-align: center">
                                <i class="las la-chart-line margin-bottom-5" style="font-size:40px;"></i>
                                <h4>Dashboard</h4>
                                <button class="btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12"
                                        @click.prevent.stop="$router.push({name:'dashboard',
                                         params: { research_project_id: $route.params.research_project_id }})">
                                    <i class="las la-eye"></i>
                                    Mostra
                                </button>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <SpinnerLoader v-show="loading"></SpinnerLoader>
    </ContainerWithMenu>
</template>

<script>
    import fileDownload from 'js-file-download'
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import SpinnerLoader from "../components/common/SpinnerLoader";
    import {downloadExcelDocument, getResearchProjectDetail} from "../api";
    import {CATEGORY_ENTRY} from "../common/constants";

    ////TODO translate this page and make dynamic this page
    export default {
        title: "Procedura nel dettaglio",
        name: "ResearchProjectDetail",
        components: { ContainerWithMenu, SpinnerLoader },
        data: function () {
            return {
                loading: false,
                project_research: {},
                deleteShowcaseModalVisible: false,
                CategoryEntry: CATEGORY_ENTRY
            }
        },
        async mounted() {
            this.ResearchProjectData()
        },
        methods: {
            async ResearchProjectData() {
                this.loading = true;
                try {
                    this.project_research = await getResearchProjectDetail(this.$route.params.research_project_id);
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            async downloadExcel(category) {
                let filename = "Medical History";
                if (category === 2) {
                    filename = "Ablation Procedure";
                } else if (category === 3) {
                    filename = "Follow-up";
                }


                this.loading = true;
                try {
                    let response = await downloadExcelDocument(this.$route.params.research_project_id, category);
                    fileDownload(response, filename + '.xlsx');
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
        },
        watch: {
            '$route.params.research_project_id': function () {
                this.ResearchProjectData()
            }
        }

    };
</script>
