var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ContainerWithMenu',[_c('div',{staticClass:"page-content"},[_c('div',{staticClass:"row margin-bottom-20"},[_c('div',{staticClass:"col-xs-12 col-md-5"},[_c('h1',{staticClass:"h2 margin-0"},[_c('i',{staticClass:"las la-dna"}),_vm._v(" "+_vm._s(_vm.project_research.name))])]),_c('div',{staticClass:"col-xs-12 col-md-7 text-right"},[_c('button',{staticClass:"btn btn-default btn-action-mobile btn-undo-mobile",on:{"click":function($event){return _vm.$router.push({name:'home'})}}},[_c('i',{staticClass:"las la-undo"}),_c('span',{staticClass:"button-text"},[_vm._v("Torna alle procedure")])])])]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.project_research.has_medical_history),expression:"project_research.has_medical_history"}],staticClass:"col-xs-12 col-sm-4"},[_c('router-link',{attrs:{"to":""}},[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body",staticStyle:{"text-align":"center"}},[_c('i',{staticClass:"las la-file-medical-alt margin-bottom-5",staticStyle:{"font-size":"40px"}}),_c('h4',[_vm._v("Medical History")]),_c('button',{staticClass:"btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$router.push({name:'entries',
                                     params: { research_project_id: _vm.$route.params.research_project_id,
                                      category_id: _vm.CategoryEntry.MEDICAL_HISTORY}})}}},[_c('i',{staticClass:"las la-eye"}),_vm._v(" Mostra ")]),_c('button',{staticClass:"btn btn-default margin-top-10 col-xs-12 col-md-12 col-lg-12",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.downloadExcel(1)}}},[_c('i',{staticClass:"las la-file-download"}),_vm._v(" Esporta ")])])])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.project_research.has_ablation),expression:"project_research.has_ablation"}],staticClass:"col-xs-12 col-sm-4"},[_c('router-link',{attrs:{"to":""}},[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body",staticStyle:{"text-align":"center"}},[_c('i',{staticClass:"las la-procedures margin-bottom-5",staticStyle:{"font-size":"40px"}}),_c('h4',[_vm._v("Ablation Procedure")]),_c('button',{staticClass:"btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$router.push({name:'entries',
                                     params: { research_project_id: _vm.$route.params.research_project_id,
                                      category_id: _vm.CategoryEntry.ABLATION_PROCEDURE}})}}},[_c('i',{staticClass:"las la-eye"}),_vm._v(" Mostra ")]),_c('button',{staticClass:"btn btn-default margin-top-10 col-xs-12 col-md-12 col-lg-12",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.downloadExcel(2)}}},[_c('i',{staticClass:"las la-file-download"}),_vm._v(" Esporta ")])])])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.project_research.has_follow_up),expression:"project_research.has_follow_up"}],staticClass:"col-xs-12 col-sm-4"},[_c('router-link',{attrs:{"to":""}},[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body",staticStyle:{"text-align":"center"}},[_c('i',{staticClass:"las la-notes-medical margin-bottom-5",staticStyle:{"font-size":"40px"}}),_c('h4',[_vm._v("Follow-up")]),_c('button',{staticClass:"btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$router.push({name:'entries',
                                     params: { research_project_id: _vm.$route.params.research_project_id,
                                      category_id: _vm.CategoryEntry.FOLLOW_UP}})}}},[_c('i',{staticClass:"las la-eye"}),_vm._v(" Mostra ")]),_c('button',{staticClass:"btn btn-default margin-top-10 col-xs-12 col-md-12 col-lg-12",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.downloadExcel(3)}}},[_c('i',{staticClass:"las la-file-download"}),_vm._v(" Esporta ")])])])])],1),_c('div',{staticClass:"col-xs-12 col-sm-4"},[_c('router-link',{attrs:{"to":""}},[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body",staticStyle:{"text-align":"center"}},[_c('i',{staticClass:"las la-users margin-bottom-5",staticStyle:{"font-size":"40px"}}),_c('h4',[_vm._v("Pazienti")]),_c('button',{staticClass:"btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$router.push({name:'patients',
                                     params: { research_project_id: _vm.$route.params.research_project_id }})}}},[_c('i',{staticClass:"las la-eye"}),_vm._v(" Mostra ")])])])])],1),_c('div',{staticClass:"col-xs-12 col-sm-4"},[_c('router-link',{attrs:{"to":""}},[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body",staticStyle:{"text-align":"center"}},[_c('i',{staticClass:"las la-chart-line margin-bottom-5",staticStyle:{"font-size":"40px"}}),_c('h4',[_vm._v("Dashboard")]),_c('button',{staticClass:"btn btn-primary margin-top-10 col-xs-12 col-md-12 col-lg-12",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$router.push({name:'dashboard',
                                     params: { research_project_id: _vm.$route.params.research_project_id }})}}},[_c('i',{staticClass:"las la-eye"}),_vm._v(" Mostra ")])])])])],1)])]),_c('SpinnerLoader',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }